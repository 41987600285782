* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.beta-film,
.nomination,
.the-goal,
.want-beta,
footer {
  width: 100%;
  background-color: #FFFFFF;
  padding: 3rem 0rem;
}

.beta-film-container,
.nomination-container,
.the-goal-container,
.want-beta-container {
  /* max-width: 50rem; */
  max-width: 68rem;
  width: 100%;
  margin: 0 auto;
}

.above-fold {
  background-color: cornsilk;
  width: 100%;
}

.above-fold nav {
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin-right: 1.5rem;
  text-align: center;
  cursor: pointer;
}

nav ul li:last-child {
  margin-right: 0rem;
}

nav ul li a {
  text-decoration: none;
  color: #7a7a7a;
}

.nav.navigator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999999999;
  background-color: cornsilk;
}

.above-fold .carousel-wrapper {
  height: calc(100vh - 5rem);
  /* background-color: #E85700; */
  background-color: #FF581A;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.above-fold .carousel {
  position: absolute;
  margin: auto;
  width: 2820px;
  height: 2350px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .center-point {
  width: fit-content;
  position: relative;
}

.carousel .center-point p,
.top-cover .center-point-text p {
  color: #FFFFFF;
  text-align: center;
}

.carousel .center-point p span,
.top-cover .center-point-text p span {
  display: block;
}

.carousel .center-point p.top-text,
.top-cover .center-point-text p.top-text {
  text-transform: uppercase;
  font-size: 1.625rem;
  font-weight: 500;
  /* opacity: 0.6; */
  transition-timing-function: ease-in-out;
}

.carousel .center-point p.main-text,
.top-cover .center-point-text p.main-text {
  color: #FFFFFF;
  font-size: 1.625rem;
  font-weight: 500;
  transition-timing-function: ease-in-out;
  margin-bottom: 2.75rem;
}

.carousel .center-point p.main-text span,
.top-cover .center-point-text p.main-text span {
  color: #FFFFFF;
}

.carousel .center-point p.buzz-text,
.top-cover .center-point-text p.buzz-text {
  font-size: 2.625rem;
  font-weight: 600;
  transition-timing-function: ease-in-out;
}

/* .center-point .center-point-text {
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  text-align: center;
  z-index: 99999999999999999;
} */

.center-point .img {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #D04304;
  box-shadow: 0 60px 80px #D04304;
}

.center-point .img img {
  height: 100%;
  left: 0;
  opacity: 0.47;
  box-shadow: 0 40px 50px #D04304;
}

.top-cover {
  background-color: #D04304;
  opacity: 0.23;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-point .img .orange-cover {
  background-color: rgba(232, 87, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/* .carousel .center-point .center-text {
  position: absolute;
  z-index: 9999999;
} */

.center-point .img-one {
  width: 329px;
  height: 264px;
  left: 30px;
  top: 240px;
}

.center-point .showOneText {
  position: absolute;
  width: 162px;
  height: fit-content;
  right: -40px;
  top: 300px;
  z-index: 99;
}

.center-point .showOneText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
  text-align: left;
}

.center-point .showOneText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-two {
  width: 370px;
  height: 288px;
  right: 450px;
  bottom: 100px;
  z-index: 9;
}

.center-point .showTwoText {
  position: absolute;
  width: 200px;
  height: fit-content;
  right: 400px;
  top: -40px;
  z-index: 99;
}

.center-point .showTwoText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showTwoText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-three {
  width: 370px;
  height: 226px;
  right: 420px;
  top: 300px;
}

.center-point .showThreeText {
  position: absolute;
  width: 220px;
  right: 375px;
  top: 275px;
  z-index: 99;
}

.center-point .showThreeText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showThreeText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-four {
  width: 329px;
  height: 288px;
  left: 50px;
  bottom: 275px;
}

.center-point .showFourText {
  position: absolute;
  width: 220px;
  height: fit-content;
  left: 0px;
  bottom: 290px;
  z-index: 99;
}

.center-point .showFourText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showFourText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-five {
  width: 220px;
  height: 300px;
  right: 550px;
  bottom: 300px;
}

.center-point .img-six {
  width: 329px;
  height: 376px;
  right: 830px;
  bottom: -80px;
}

.center-point .showSixText {
  position: absolute;
  width: 220px;
  height: fit-content;
  right: 680px;
  bottom: 0px;
  z-index: 999;
}

.center-point .showSixText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showSixText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-seven {
  width: 329px;
  height: 365px;
  left: 450px;
  bottom: 150px;
}

.center-point .showSevenText {
  position: absolute;
  width: 220px;
  height: fit-content;
  left: 400px;
  bottom: 170px;
  z-index: 999;
}

.center-point .showSevenText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showSevenText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-eight {
  width: 329px;
  height: 295px;
  left: 480px;
  top: 80px;
}

.center-point .showEightText {
  position: absolute;
  width: 220px;
  height: fit-content;
  left: 680px;
  top: 250px;
  z-index: 9999999999;
}

.center-point .showEightText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showEightText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .img-nine {
  width: 331px;
  height: 391px;
  left: 850px;
  bottom: -80px;
}

.center-point .img-ten {
  width: 240px;
  height: 400px;
  left: 400px;
  top: 250px;
}

.center-point .showTenText {
  position: absolute;
  width: 220px;
  height: fit-content;
  left: 400px;
  top: 250px;
  z-index: 9999999999;
  padding: 0.25rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: flex-start;
}

.center-point .showTenText p {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.center-point .showTenText p span {
  color: #FFFFFF;
  display: block;
  text-align: left;
}

.media-box {
  height: 25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.want-beta-container .media-box {
  height: 35rem;
}

.media-box > img:first-child {
  width: 100%;
}

.media-box .black-cover {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.media-box .purple-cover {
  background-color: #5F0CE3;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.media-overlay {
  position: absolute;
  margin: auto;
  z-index: 999999999;
}

.media-overlay span,
.media-overlay i {
  color: #FFFFFF;
}

img.media-overlay {
  height: 20rem;
  z-index: 9999999;
}

p.media-overlay {
  z-index: 9999999;
}

p.media-overlay {
  font-size: 2rem;
}

p.title {
  text-align: center;
  color: #FF691A;
  font-weight: 500;
  font-size: 2rem;
}

p.description {
  text-align: center;
  color: #7a7a7a;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 300;
}

.button {
  padding: 0;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  border-radius: 2rem;
  background-color: #FFFFFF;
  border: 1px solid #E85700;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button a {
  display: block;
  /* height: 2.75rem; */
  padding: 1rem 1.5rem;
  color: #E85700;
  text-decoration: none;
}

.button:hover {
  background-color: #E85700;
}

.button:hover a {
  color: #FFFFFF;
}



footer {
  padding: 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

footer p {
  color: #b6b6b6;
  font-size: 0.85rem;
  font-weight: 300;
}




@media (max-width: 480px) {
  .media-box {
    height: fit-content;
  }
}



/* Helper Styles */
.enbolden {
  color: #000000;
  font-weight: 300;
}

.resize { width: 100%; }

.bg-grey {
  background-color: #F7F7F7;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.ps-half {
  padding: 0.5rem;
}



