* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #464646;
}

header {
    height: 20.8rem;
    overflow: hidden;
    position: relative;
    background-image: url('./assets/iStock-10397642367.jpg');
}

.orange-cover {
    background-color: #D04304;
    opacity: 0.55;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
}

.header-box {
    max-width: 50rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 0rem;
    /* justify-content: flex-start; */
}

.page-heading {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 3.75rem;
    z-index: 999;
}

.nomination-form {
    padding-bottom: 8rem;
}

.nominee,
.nominator,
.acknowledge,
.pre-form,
.policy-list {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
}

.header {
    background-color: #EBEBEB;
    color: #6A6A6A;
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 0.25rem;
    font-weight: 500;
}

.pre-form {
    padding: 2rem 0rem;
}

.inputs {
    display: flex;
    justify-content: space-between;
}

.acknowledge .inputs {
    justify-content: flex-start;
}

.acknowledge .inputs .radio {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.acknowledge .inputs .radio input {
    margin-right: 0.5rem;
}

.policy-title {
    font-size: 1.5rem;
    font-weight: 400;
}

.policy .policy-desc {
    color: #818181;
    font-size: 0.75rem;
}




.header-text {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 300;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1-5 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

input[type='text'],
input[type='number'] {
    /* border: 1px solid #4C84FF; */
    border: 1px solid #E3E3E3;
    height: 5.875rem;
    border-radius: 0.25rem;
    background-color: #FFFFFF;
    font-size: 1rem;
    height: 3rem;
    flex: 1;
    padding-left: 1.5rem;
    outline: none;
}

input[type='text']:focus,
input[type='number']:focus {
    border: 1px solid #4C84FF;
}

input[type='text']:first-child {
    margin-right: 1rem;
}

.single-inputs input {
    margin-right: 0rem;
}

.single-input > input {
    width: 100%;
}

.age-range {
    position: relative;
}

.age-range input {
    width: 48.5%;
    cursor: pointer;
}

.age-range i {
    margin-left: -2.5rem;
}

.age-range .drop-down {
    position: absolute;
    top: 55px;
    width: 48.5%;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 0.2rem;
    padding: 1rem 0rem;
}

.age-range .drop-down p {
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-size: 0.8rem;
}

.age-range .drop-down p:hover {
    background-color: #FAFAFA;
}

.single-inputs {
    display: flex;
}

.single-inputs .input {
    flex: 1;
}

.single-inputs .input:first-child {
    margin-right: 1rem;
}

.single-inputs .input input {
    display: block;
    width: 100%;
}

.phone-number {
    position: relative;
}

.phone-number span {
    display: block;
    background-color: #F5F5F5;
    border: 1px solid #E3E3E3;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    font-size: 1rem;
    height: 3rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1.25rem;
    position: absolute;
    bottom: 0;
}

.phone-number input {
    padding-left: 6.5rem;
}

.phone-number input:focus ~ span {
    border: 1px solid #4C84FF;
    border-right: 1px solid #E3E3E3;
}

input::placeholder {
    color: #B7B7B7;
}

.sub-button button {
    margin: 0 auto;
    border-radius: 0.25rem;
    background-color: #E85700;
    color: #FFFFFF;
    border: 1px solid #E85700;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 4.5rem; */
    padding: 1rem 2rem;
    font-size: 1.25rem;
    outline: none;
}

.sub-button button:hover {
    transform: scale(0.975);
}

.sub-button button:disabled {
    background-color: #B7B7B7;
}

.policies {
    border-top: 1px solid #B7B7B7;
    border-bottom: 1px solid #B7B7B7;
    padding-top: 2rem;
    margin-bottom: 3rem;
}

.btn-general {
    border: none;
    outline: none;
    width: 240px;
    height: 50px;
    color: #FFFFFF;
    border-radius: 0.5rem;
    background-color: #E85700;
    cursor: pointer;
}

.pre-form > a {
    border: none;
    outline: none;
    width: 240px;
    height: 50px;
    color: #FFFFFF;
    border-radius: 0.5rem;
    margin-right: 1rem;
}

.btn-general:hover {
    transform: scale(0.95);
}

.form-loading {
    text-align: center;
}


@media (max-width: 480px) {
    .inputs {
        flex-direction: column;
        justify-content: flex-start;
    }

    input[type='text']:first-child {
        margin-right: 0rem;
    }

    input {
        height: 3rem;
    }
}

.nav.navigator { top: 0 }

footer {
    margin-top: 8rem;
    padding: 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

footer p {
    color: #b6b6b6;
    font-size: 0.85rem;
    font-weight: 300;
}

