.nominations {
    width: 100%;
}

.nominations-container,
.main-title {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    /* overflow-x: scroll; */
}

/* .x-scroll {
    min-width: 1920px;
} */

.main-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.nominations p {
    text-align: center;
}

.nominations .heading {
    color: #D04304;
    font-weight: 200;
    font-size: 5rem;
}




.nominations-container {
    display: flex;
    flex-wrap: wrap;
}

.single-nomination {
    width: 400px;
    height: fit-content;
    padding: 1rem;
    background-color: #EBEBEB;
    border-radius: 0.4rem;
    margin-right: 1rem;
}

.single-nomination p {
    text-align: left;
}

.single-nomination p.heading-text {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.single-nomination > .nominee {
    padding-bottom: 2rem;
}

.single-nomination > .nominator {
    padding-bottom: 0rem;
}

.single-nomination > .nominee p,
.single-nomination > .nominator p {
    color: #464646;
    margin-bottom: 0.2rem;
}

.single-nomination > .nominee p span,
.single-nomination > .nominator p span {
    font-weight: 300;
}

.btn-general2 {
    border: none;
    outline: none;
    width: 240px;
    height: 50px;
    color: #FFFFFF;
    border-radius: 0.5rem;
    background-color: #E85700;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.btn-general2:hover {
    transform: scale(0.95);
}





/* ul {
    list-style-type: none;
    display: flex;
}

ul li {
    min-width: 180px;
    margin-right: 0.2rem;
    color: #7a7a7a;
}

ul li:last-child {
    margin-right: 0rem;
}

.table-header {
    background-color: cornsilk;
    padding: 0.5rem;
    width: inherit;
}

.table-row {
    padding: 0.5rem;
}

.table-row:nth-child(odd) {
    background-color: #EBEBEB;
} */

.loading {
    width: 100%;
    text-align: left !important;
}
